import DefaultApiService from '@/services/defaultApi.service'

const VUE_APP_ITAPPLICATION_API_ENDPOINT = process.env.VUE_APP_ITAPPLICATION_API_ENDPOINT

const defaultApiService = new DefaultApiService(VUE_APP_ITAPPLICATION_API_ENDPOINT)
const LIST_ITAPPLICATION = 'itApplications'
const GET_ITAPPLICATION = 'getItApplication'
const SAVE_ITAPPLICATION = 'saveItApplication'
const DELETE_ITAPPLICATION = 'deleteItApplication'
const LIST_FUNCTION_TYPES = 'functionTypes'
const GET_USER_FUNCTIONS = 'getUserFunctions'
const RELEASE_ITAPPLICATION = 'releaseItApplication'
const COMPLETE_ITAPPLICATION = 'completeItApplication'
const REVERT_ITAPPLICATION = 'revertItApplication'
const REQUEST_ITAPPLICATION = 'requestItApplication'
const REJECT_ITAPPLICATION = 'rejectItApplication'
const LIST_ALL_ORGS_FUNCTION_TYPES = 'listAllOrgsFunctionTypes'
const LIST_ALL_ORGS = 'listAllOrgs'
const LIST_FUNC_TYPE = 'listFuncType'
const LIST_IT_APPLICATION_STATES = 'listItApplicationStates'
const SEND_IT_APPLICATION_FOR_REQUESTING = 'sendItApplicationForRequesting'
const SEARCH_USER = 'searchUser'
const LIST_IT_APPLICATION_HISTORY = 'listItApplicationHistory'
const ASSIGN_IDM_USER_FUNCTIONS = 'assignIdmUserFunctions'
const GET_CHANGED_FUNCTIONS = 'getChangedFunctions'
const FIND_USERS = 'findUsers'
const GET_VALID_AFFS = 'getValidAffs'
const EXPIRE_IDM_USER_FUNCTIONS = 'expireIdmUserFunctions'
const MANUALLY_ADD_IDM_USER = 'manuallyAddIdmUser'

class ItApplicationService {
  listItApplication (params) {
    return defaultApiService.list(LIST_ITAPPLICATION, params)
  }

  listFunctionTypes (params) {
    return defaultApiService.list(LIST_FUNCTION_TYPES, params)
  }

  getItApplication (id, params) {
    return defaultApiService.get(GET_ITAPPLICATION, id, params)
  }

  saveItApplication (data, params) {
    return defaultApiService.update(SAVE_ITAPPLICATION, data, params)
  }

  deleteItApplication (id) {
    return defaultApiService.delete(DELETE_ITAPPLICATION, id)
  }

  getUserFunctions () {
    return defaultApiService.get(GET_USER_FUNCTIONS)
  }

  releaseItApplication (data, params) {
    return defaultApiService.update(RELEASE_ITAPPLICATION, data, params)
  }

  completeItApplication (data, params) {
    return defaultApiService.update(COMPLETE_ITAPPLICATION, data, params)
  }

  revertItApplication (data, params) {
    return defaultApiService.update(REVERT_ITAPPLICATION, data, params)
  }

  rejectItApplication (data, params) {
    return defaultApiService.update(REJECT_ITAPPLICATION, data, params)
  }

  requestItApplication (data, params) {
    return defaultApiService.update(REQUEST_ITAPPLICATION, data, params)
  }

  listAllOrgsFunctionTypes (params) {
    return defaultApiService.list(LIST_ALL_ORGS_FUNCTION_TYPES, params)
  }

  listAllOrgs (params) {
    return defaultApiService.list(LIST_ALL_ORGS, params)
  }

  listFuncType (params) {
    return defaultApiService.list(LIST_FUNC_TYPE, params)
  }

  listItApplicationStates (params) {
    return defaultApiService.list(LIST_IT_APPLICATION_STATES, params)
  }

  searchUser (params) {
    return defaultApiService.list(SEARCH_USER, params)
  }

  sendItApplicationForRequesting (data, params) {
    return defaultApiService.update(SEND_IT_APPLICATION_FOR_REQUESTING, data, params)
  }

  listItApplicationHistory (params) {
    return defaultApiService.list(LIST_IT_APPLICATION_HISTORY, params)
  }

  assignIdmUserFunctions (data, params) {
    return defaultApiService.update(ASSIGN_IDM_USER_FUNCTIONS, data, params)
  }

  getChangedFunctions (params) {
    return defaultApiService.list(GET_CHANGED_FUNCTIONS, params)
  }

  findUsers (params) {
    return defaultApiService.list(FIND_USERS, params)
  }

  getValidAffs (params) {
    return defaultApiService.list(GET_VALID_AFFS, params)
  }

  expireIdmUserFunctions (data, params) {
    return defaultApiService.update(EXPIRE_IDM_USER_FUNCTIONS, data, params)
  }

  manuallyAddIdmUser (data, params) {
    return defaultApiService.update(MANUALLY_ADD_IDM_USER, data, params)
  }
}

export default new ItApplicationService()
