<template>
  <span class="user" v-if="isReady">
      <multiselect :id="id" :options="items" v-model="value" :placeholder="$t('user.placeholder')" :loading="searching"
                   :searchable="true" :internal-search="false" @search-change="searchUserMin" @input="onInput"
                   track-by="id" label="_label" :selectLabel="$t('select.label')"
                   :selectGroupLabel="$t('selectGroup.label')" :selectedLabel="$t('selected.label')"
                   :deselectLabel="$t('deselect.label')" :deselectGroupLabel="$t('deselectGroup.label')">
        <template slot="singleLabel" slot-scope="props">
          <span v-if="showUsername && props.option.username" class="mr-1">({{ props.option.username }})</span>
          <span v-if="props.option._label" class="mr-1">{{ props.option._label }}</span>
          <span v-if="showMail && props.option.mail">&lt;{{ props.option.mail }}&gt;</span>
        </template>
        <template slot="option" slot-scope="props">
          <span v-if="showUsername && props.option.username" class="mr-1">({{ props.option.username }})</span>
          <span v-if="props.option._label" class="mr-1">{{ props.option._label }}</span>
          <span v-if="showMail && props.option.mail">&lt;{{ props.option.mail }}&gt;</span>
        </template>
        <template slot="noOptions">{{ $t('user.search.hint') }}</template>
        <template slot="noResult" v-if="!items.length">{{ $t('noResult.label') }}</template>
      </multiselect>
  </span>
</template>

<script>
import Multiselect from 'vue-multiselect'
import AdminService from '@/services/admin.service'
import ItApplicationService from '@/services/itApplication.service'
import _ from 'lodash'
export default {
  name: 'SelectUserMin',
  components: {
    Multiselect
  },
  props: {
    id: {
      type: String,
      default: 'user'
    },
    userId: String,
    username: String,
    showUsername: Boolean,
    showMail: Boolean,
    state: Boolean,
    searchItApplicationRequester: Boolean,
    searchItApplicationManager: Boolean,
    searchItApplicationSupervisor: Boolean
  },
  data: function () {
    return {
      searching: false,
      isReady: false,
      value: {},
      items: []
    }
  },
  computed: {
  },
  watch: {
    userId (n, o) {
      this.load()
    },
    username (n, o) {
      this.load()
    }
  },
  methods: {
    onInput (user) {
      this.value = user
      this.$emit('update:user-id', _.get(user, 'id'))
      this.$emit('update:username', _.get(user, 'username'))
    },
    searchUserMin: _.debounce(function (q) {
      if (q && q.length > 1) {
        this.searching = true
        if (this.searchItApplicationRequester) {
          ItApplicationService.searchUser({ q: q, filter: 'requester' }).then((response) => {
            this.items = response.items
            this.searching = false
          })
        } else if (this.searchItApplicationManager) {
          ItApplicationService.searchUser({ q: q, filter: 'manager' }).then((response) => {
            this.items = response.items
            this.searching = false
          })
        } else if (this.searchItApplicationSupervisor) {
          ItApplicationService.searchUser({ q: q }).then((response) => {
            this.items = response.items
            this.searching = false
          })
        } else {
          AdminService.list('searchUserMin', { q: q }).then((response) => {
            this.items = response.items
            this.searching = false
          })
        }
      }
    }, 300),
    load () {
      if (this.userId || this.username) {
        if (this.searchItApplicationRequester) {
          ItApplicationService.searchUser({ q: this.userId || this.username, filter: 'requester' }).then((response) => {
            this.items = response.items
            this.value = _.first(this.items) || {}
            this.isReady = true
          })
        } else if (this.searchItApplicationManager) {
          ItApplicationService.searchUser({ q: this.userId || this.username, filter: 'manager' }).then((response) => {
            this.items = response.items
            this.value = _.first(this.items) || {}
            this.isReady = true
          })
        } else if (this.searchItApplicationSupervisor) {
          ItApplicationService.searchUser({ q: this.userId || this.username }).then((response) => {
            this.items = response.items
            this.value = _.first(this.items) || {}
            this.isReady = true
          })
        } else {
          AdminService.list('searchUserMin', { q: this.userId || this.username }).then((response) => {
            this.items = response.items
            this.value = _.first(this.items) || {}
            this.isReady = true
          })
        }
      } else {
        this.onInput(null)
        this.isReady = true
      }
    }
  },
  beforeMount () {
    this.load()
  }
}
</script>
