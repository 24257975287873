import Vue from 'vue'
import VueRouter from 'vue-router'
import Start from '@/views/Start'
import store from '@/store/index'
import _ from 'lodash'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'start',
    component: Start
  },
  {
    path: '/loggedout',
    name: 'loggedout',
    component: () => import(/* webpackChunkName: "loggedout" */ '../views/LoggedOut.vue')
  },
  {
    path: '/errorlogin',
    name: 'errorlogin',
    component: () => import(/* webpackChunkName: "errorlogin" */ '../views/ErrorLogin.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/activate/:id?',
    name: 'Activate',
    component: () => import('../views/Activate.vue'),
    props: true
  },
  {
    path: '/password/recovery/:id?',
    name: 'PasswordRecovery',
    component: () => import('../views/PasswordRecovery.vue'),
    props: true
  },
  {
    path: '/password/reset/:id?',
    name: 'PasswordReset',
    component: () => import('../views/PasswordReset.vue'),
    props: true
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue')
  },
  {
    path: '/flow',
    name: 'Flow',
    component: () => import(/* webpackChunkName: "flow" */ '../views/Flow.vue')
  },
  {
    path: '/admin/user/:id?',
    name: 'AdminUser',
    component: () => import('../components/admin/User.vue'),
    props: true,
    meta: { authorize: ['ROLE_PORTALADMIN', 'ROLE_ADMIN'] }
  },
  {
    path: '/admin/:tab?/:id?',
    name: 'admin',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    meta: { authorize: ['ROLE_PORTALADMIN', 'ROLE_ADMIN'] }
  },
  {
    path: '/conf/:tab?/:id?',
    name: 'conf',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/Conf.vue'),
    meta: { authorize: ['ROLE_PORTALADMIN', 'ROLE_ADMIN'] }
  },
  {
    path: '/root/:tab?/:id?',
    name: 'root',
    props: true,
    component: () => import(/* webpackChunkName: "root" */ '../views/Root.vue'),
    meta: { authorize: ['ROLE_ADMIN'] }
  },
  {
    path: '/recoveryMail/confirm/:id?',
    name: 'RecoveryMailConfirm',
    component: () => import('../views/RecoveryMailConfirm.vue'),
    props: true
  },
  {
    path: '/directory/:id?',
    name: 'Directory',
    component: () => import('../views/Directory.vue'),
    props: true,
    meta: { authorize: ['ROLE_ACTIVATED'] }
  },
  {
    path: '/itapplications/',
    name: 'ItApplications',
    component: () => import('../views/ItApplications.vue'),
    props: true,
    meta: {
      authorize: ['ROLE_PORTALADMIN', 'ROLE_ADMIN', 'ROLE_ITAPPLICATION']
    }
  },
  {
    path: '/itapplications/itapplication/:id?',
    name: 'ItApplication',
    component: () => import('../components/itApplication/ItApplication.vue'),
    props: true,
    meta: { authorize: ['ROLE_PORTALADMIN', 'ROLE_ADMIN', 'ROLE_ITAPPLICATION'] }
  },
  {
    path: '/itapplications/itapplicationhistory/:id?',
    name: 'ItApplicationHistory',
    component: () => import('../components/itApplication/ItApplicationHistory.vue'),
    props: true,
    meta: { authorize: ['ROLE_PORTALADMIN', 'ROLE_ADMIN', 'ROLE_ITAPPLICATION'] }
  },
  {
    path: '/itapplications/itapplicationdetails/:id?',
    name: 'ItApplicationDetails',
    component: () => import('../components/itApplication/ItApplicationDetails.vue'),
    props: true,
    meta: { authorize: ['ROLE_PORTALADMIN', 'ROLE_ADMIN', 'ROLE_ITAPPLICATION'] }
  },
  {
    path: '/itapplications/assignIdmFunctions/:id?',
    name: 'ItApplicationAssignIdmUserFunctions',
    component: () => import('../components/itApplication/ItApplicationAssignIdmUserFunctions.vue'),
    props: true,
    meta: { authorize: ['ROLE_PORTALADMIN', 'ROLE_ADMIN'] }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta
  const publicPages = ['/', '/start', '/loggedout', '/errorlogin', '/register']
  const authRequired = !(publicPages.includes(to.path) || to.path.startsWith('/activate') || to.path.startsWith('/password/reset/') || to.path.startsWith('/password/recovery') || to.path.startsWith('/recoveryMail/confirm/'))
  if (!store.state.auth.user) {
    await store.dispatch('auth/ping')
  }
  const loggedIn = store.state.auth.user
  let pass = true
  if (authorize && loggedIn) {
    const roles = store.state.auth.user.roles
    if (_.intersection(roles, authorize).length === 0) {
      pass = false
    }
  }

  if (!pass || (authRequired && !loggedIn)) {
    if (to.fullPath !== '/portal/') {
      store.commit('auth/redirectAfterLogin', to.fullPath)
    }
    next('/')
  } else {
    next()
  }
})

export default router
